<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">设置</span>
		</div>
		<div class="bg-white p-2 m-2">
			<el-form ref="form" :model="form" label-width="180px">
				<el-card shadow="never" class="mt-24">
					<el-row>
						<el-form-item label="阿里物流查询appcode">
							<el-input v-model="form.store.Aliyun.appcode"></el-input>
						</el-form-item>
					</el-row>
				</el-card>
				<el-card shadow="never" class="mt-24">
					<div slot="header">
						<span>福卡设置</span>
					</div>
					<el-row>
						<el-form-item label="每个景点获得福卡数">
							<el-input-number v-model="form.share.today_scenic_focas_number"></el-input-number>
						</el-form-item>
						<el-form-item label="每天分享获得福卡数">
							<el-input-number v-model="form.share.today_share_focas_number"></el-input-number>
						</el-form-item>
					</el-row>
				</el-card>
				<el-card shadow="never" class="mt-24">
					<div slot="header">
						<span>分享设置</span>
					</div>
					<el-row>
						<el-form-item label="分享标题">
							<el-input v-model="form.share.title"></el-input>
						</el-form-item>
						<el-form-item label="分享图片">
							<app-attachment :multiple="false" :max="1" v-model="form.share.image">
								<el-button style="margin-bottom: 10px;" size="mini">选择文件</el-button>
							</app-attachment>
							<app-gallery :url="form.share.image" :show-delete="true" @deleted="form.share.image = ''"
								width="80px" height="80px"></app-gallery>
						</el-form-item>
					</el-row>
				</el-card>
				<el-card shadow="never" class="mt-24">
					<div slot="header">
						<span>地址设置</span>
					</div>
					<el-row>
						<el-form-item label="地址修改">
							<el-radio v-model="form.address.is_edit" value="10" label="10">
								开启
							</el-radio>
							<el-radio v-model="form.address.is_edit" value="20" label="20">
								关闭
							</el-radio>
						</el-form-item>
					</el-row>
				</el-card>
				<el-card shadow="never" class="mt-24">
					<div slot="header">
						<span>活动设置</span>
					</div>
					<el-row>
						<el-form-item label="是否开启">
							<el-radio v-model="form.activity.is_open" value="10" label="10">
								开启
							</el-radio>
							<el-radio v-model="form.activity.is_open" value="20" label="20">
								关闭
							</el-radio>
						</el-form-item>

						<el-form-item label="活动说明">
							<app-rich-text v-model="form.activity.open_explain"></app-rich-text>
						</el-form-item>
						<el-form-item label="关闭活动说明">
							<app-rich-text v-model="form.activity.close_explain"></app-rich-text>
						</el-form-item>
					</el-row>
				</el-card>
				<el-card shadow="never" class="mt-24">
					<div slot="header">
						<span>上传设置</span>
					</div>
					<el-row>
						<el-form-item label="默认上传方式">
							<el-radio v-model="form.storage.default" value="local" label="local">
								本地（不推荐）
							</el-radio>
							<el-radio v-model="form.storage.default" value="qiniu" label="qiniu">
								七牛云存储
							</el-radio>
							</el-radio-group>
						</el-form-item>
						<template v-if="form.storage.default == 'qiniu'">
							<el-form-item label="存储空间名称 Bucket">
								<el-input v-model="form.storage.engine.qiniu.bucket"></el-input>
							</el-form-item>
							<el-form-item label="ACCESS_KEY AK">
								<el-input v-model="form.storage.engine.qiniu.access_key"></el-input>
							</el-form-item>
							<el-form-item label="SECRET_KEY SK">
								<el-input v-model="form.storage.engine.qiniu.secret_key"></el-input>
							</el-form-item>
							<el-form-item label="空间域名 Domain">
								<el-input v-model="form.storage.engine.qiniu.domain"></el-input>
							</el-form-item>
						</template>
					</el-row>
				</el-card>
				<el-form-item>
					<el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import appRichText from '@/components/app-rich-text.vue';
	export default {
		inject: ['app', 'layout'],
		components: {
			appRichText
		},
		data() {
			return {
				loading: false,
				form: {
					share: {
						image: '',
						title: '',
					},
					focas: {
						today_share_focas_number: 0,
						today_scenic_focas_number: 0,
					},
					store: {
						Aliyun: {
							appcode: 'fd0a000a2df2442680e6b68daeb28d4a'
						}
					},
					address: {
						is_edit: 10
					},
					activity: {
						is_open: 10,
						open_explain: '',
						close_explain: '',
					},
					storage: {
						default: 'local',
						engine: {
							local: {},
							qiniu: {
								bucket: '',
								access_key: '',
								secret_key: '',
								domain: 'http://'
							},
						},
					}
				},
			}
		},
		created() {
			this.getSetting();
		},
		methods: {
			getSetting() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/setting/setting',
					},
					method: 'get',
				}).then(res => {
					this.form = res.data.data
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			},

			// 提交
			submit() {
				this.loading = true;
				this.axios({
					token: true,
					params: {
						s: 'store/setting/setting',
					},
					data: {
						setting: this.form
					},
					method: 'post',
				}).then(res => {
					if (res.data.code == 1) {
						this.$message({
							message: res.data.msg,
							type: 'success'
						});
						// 跳转后台首页
						this.$router.push({
							name: 'setting/setting'
						})
					} else {
						this.$message({
							message: res.data.msg,
							type: 'error'
						});
					}
					this.loading = false;
				}).catch(err => {
					this.loading = false;
				})
			},
		},
	}
</script>

<style>
	.mt-24 {
		margin-bottom: 24px;
	}
</style>
